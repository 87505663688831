import { LoginPage, useAuth } from '@upper/auth'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

export default function Login() {
  const router = useRouter()
  const { login } = useAuth()
  const handleLoginSubmit = useCallback(
    async (values: any) => {
      try {
        await login(values)
      } catch (e: any) {
        if (e.data?.errorCode === 'userNotVerified') {
          router.push({
            pathname: '/register/confirm-email',
            query: { email: values.email },
          })
        } else if (e.data.code === 'UNAUTHENTICATED') {
          toast.error('Username or password are invalid', { id: 'login-error' })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login]
  )
  return (
    <>
      <Head>
        <title>Login - Upper Freelancer</title>
      </Head>

      <LoginPage
        appName="Freelancers"
        background={
          <img
            src="/images/login-bg.jpg"
            alt=""
            className="absolute top-0 left-0 w-[2148px] h-[1544px] max-w-none"
          />
        }
        onSubmit={handleLoginSubmit}
      />
    </>
  )
}

Login.redirectAuthenticatedTo = '/'
